import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Root} from './Root';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {PricingPage} from './components/PricingPage';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { TransactionComplete } from './components/TransactionComplete';
import { ErrorPage } from './components/ErrorPage';
import { LoginPage } from './components/LoginPage';
import { HomePage } from './components/HomePage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, GRAPHQL_URL } from './App.constants';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true, 
        element: <HomePage />
      },
      {
        path: 'pricing',
        element: <PricingPage />
      },
      {
        path: 'login',
        element: <LoginPage />
      }
    ]
  },
  {
    path: '/transactionComplete',
    element: <TransactionComplete />
  }
])

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultTheme = createTheme({
  typography: {
    fontFamily: '\'Poppins\', Arial',
  },
});

root.render(
  <React.StrictMode>
    <Auth0Provider 
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <ApolloProvider client={client}>
        <ThemeProvider theme={defaultTheme}>  
          <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
          <CssBaseline /> 
          <RouterProvider router={router} />
        </ThemeProvider>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
