import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { LinkRouter } from './components/LinkRouter';
import { LoginButton } from './components/LoginButton';
import { useAuth0 } from '@auth0/auth0-react';
import { UserMenu } from './components/UserMenu';
import { Outlet } from 'react-router-dom';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.clearcibil.com/">
        ClearCibil
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const footers = [
  {
    title: 'Follow Us',
    description: [
      {
        text: 'Instagram',
        href: 'https://instagram.com/clear_cibil?igshid=YmM0MjE2YWMzOA==',
      },
      {
        text: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100093106113816&mibextid=LQQJ4d',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/CibilClear89286'
      }
    ],
  },
  {
    title: 'Legal',
    description: [
      {
        text: 'Terms and Conditions',
        href: 'https://www.clearcibil.com/terms-and-conditions'
      },
      {
        text: 'Privacy Policy',
        href: 'https://www.clearcibil.com/privacy-policy'
      },
      {
        text: 'Refund and Cancellaton Policy',
        href: 'https://www.clearcibil.com/cookie-policy'
      } 
    ],
  },
];

export const Root: React.FC = () => {
  const {isAuthenticated, isLoading} = useAuth0();

  return (
     <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            ClearCibil
          </Typography>
          <nav>
            <LinkRouter
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5 }}
              to="/pricing"
            >
              Pricing
            </LinkRouter>
          </nav>
          {isLoading ? <Typography variant='button'>Loading...</Typography> : isAuthenticated ? (
            <UserMenu />
          ) : (
            <LoginButton variant="outlined" />
          )}
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
        <Outlet />
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          <Grid item xs={6} sm={3}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Contact Us
              </Typography>
              <ul>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    Simha Arcade, MKK Road, <br />
                    Rajajinagar, Bangalore <br />
                    560021
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    <Link href="mailto:support@clearcibil.com">
                    support@clearcibil.com
                    </Link>
                  </Typography>
                </li>
              </ul>
            </Grid>
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item, i) => (
                    <li key={i}>
                      <Link href={item.href} variant="subtitle1" color="text.secondary">
                        {item.text}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </> 
  );
}