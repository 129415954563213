import { gql, useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { LinkRouter } from "./LinkRouter";
import { Home } from "@mui/icons-material";

const PURCHASE_STATUS_QUERY = gql`
  query GetPruchaseStatus($userId: String!, $transactionId: String!, $mobileNum: String!) {
    checkPurchaseStatus(userId: $userId, transactionId: $transactionId, mobileNum: $mobileNum) {
      payload
    }
  }
`

export const TransactionComplete: React.FC = () => {
  const [searchParams] = useSearchParams();
  const variables = useMemo(() => ({
    userId: searchParams.get('u'),
    transactionId: searchParams.get('t'),
    mobileNum: searchParams.get('m')
  }), [searchParams]);
  const {loading, error, data} = useQuery(PURCHASE_STATUS_QUERY, {
    variables
  });

  if (loading) return <>Loading...</>;

  if (error) return <h1>ERROR: {error.toString()}</h1>

  const ppResponse = JSON.parse(data.checkPurchaseStatus.payload);
  const isPaymentSuccess = ppResponse.code === 'PAYMENT_SUCCESS';
  const isPaymentPending = ppResponse.code === 'PAYMENT_PENDING';

  return(
    <Stack sx={{ minWidth: 275, height: '100vh' }} alignItems={'center'} justifyContent={'center'}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
            ClearCibil
          </Typography>
          <Typography variant="h5" component="div">
            {ppResponse.success ? '✅': '🛑'}
            {' '}
            {ppResponse.code}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Transaction ID: {ppResponse.data.transactionId}
          </Typography>
          <Typography variant="body2">
            {ppResponse.message}
            <br />
            {isPaymentSuccess ? (
              <>Thank You! We will get in touch with you shortly.</>
            ) : null}
          </Typography>
        </CardContent>
        <CardActions>
          {!isPaymentPending ? (
            <Button startIcon={<Home />}>
              <LinkRouter
                to="https://clearcibil.com"
              >
                Go Home
              </LinkRouter>
            </Button>
          ) : null}
        </CardActions>
      </Card>
    </Stack>
  )
  
  // return (
  //   <>
  //     <h1>{ppResponse.success ? '✅': '🛑'}</h1>
  //     <h2>{ppResponse.code}</h2>
  //     <p>{ppResponse.message}</p>
  //     <p>Transaction ID: {ppResponse.data.transactionId}</p>
  //   </>
  // )
}