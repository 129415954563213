import React from "react";  
import { useAuth0 } from "@auth0/auth0-react";

export const LoginPage: React.FC = () => {
  const {loginWithRedirect} = useAuth0();
  loginWithRedirect()
  return (
      <p>Please wait...</p>
  )
};

