// Production values
const GRAPHQL_URL='https://api.clearcibil.com';
const AUTH0_DOMAIN='clearcibil.us.auth0.com';
const AUTH0_CLIENT_ID='dsxSdFeuIZqH7DdA9K8DQ25LUq6DdtDm';

// Development values
// const GRAPHQL_URL='http://localhost:4000';
// const AUTH0_DOMAIN='goje.us.auth0.com';
// const AUTH0_CLIENT_ID='6czp5n85VEIc4o4MlU4YP7eSDtcK9SpO';

export {
  GRAPHQL_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN
}