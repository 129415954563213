import { useAuth0 } from "@auth0/auth0-react"
import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material"
import { useState } from "react";

export const UserMenu: React.FC = () => {
  const {user, logout} = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton
        id="user-icon" 
        aria-label="user menu" 
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu 
        id="user-menu" 
        anchorEl={anchorEl} 
        open={isOpen} 
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem>{user?.name}</MenuItem>
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>
    </div>
  )
}