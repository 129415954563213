import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const HomePage: React.FC = () => {
  const {isLoading} = useAuth0();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isLoading) navigate('/pricing')
  }, [isLoading, navigate]);
  
  return <>Please wait...</>;
}