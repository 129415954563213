import { useAuth0 } from "@auth0/auth0-react";
import { PropsOf } from "@emotion/react";
import { Button } from "@mui/material";

export const LoginButton: React.FC<PropsOf<typeof Button>> = ({onClick, ...props}) => {
  const {loginWithRedirect} = useAuth0();

  return (
    <Button {...props} onClick={(...args) => {
      onClick?.(...args);
      loginWithRedirect();
    }}>
      Login
    </Button>
  )
}