import React from "react";
import { useRouteError } from "react-router-dom";

type PageError = {
  statusText?: string;
  message?: string;
}

export const ErrorPage: React.FC = () => {
  const error: PageError = useRouteError() as PageError;
  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorrt, an unexpected error has occurred.</p>
      <p><a href="/">Click here</a> to go back to home page.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}
  