import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { PurchaseButton } from './PurchaseButton';
import { PropsOf } from '@emotion/react';

interface Plan {
  title: string,
  subheader: string,
  type: PlanType,
  price: number,
  description: string[],
  buttonVariant: PropsOf<typeof PurchaseButton>['variant']
}

export enum PlanType {
  Basic = 'Basic',
  Standard = 'Standard',
  Professional = 'Professional'
}

const plans: Plan[] = [
  {
    title: 'Basic',
    subheader: 'Perfect to know credit score and get the credit report',
    type: PlanType.Basic,
    price: 499,
    description: [
      'Access to credit reports',
      'Detailed credit report analysis',
      'Finding inaccuracies in report',
    ],
    buttonVariant: 'outlined',
  },
  {
    title: 'Standard',
    subheader: 'Perfect to know the inaccuracies and understand the report',
    type: PlanType.Standard,
    price: 1999,
    description: [
      'Access to credit reports',
      'Detailed credit report analysis',
      'Finding inaccuracies in report',
      'Dispute guidance',
    ],
    buttonVariant: 'contained',
  },
  {
    title: 'Professional',
    subheader: 'End-to-end credit report analysis and monitoring',
    type: PlanType.Professional,
    price: 3499,
    description: [
      'Access to credit reports',
      'Detailed credit report analysis',
      'Finding inaccuracies in report',
      'Dispute escalation with bureaus',
      'Personalized credit building plan',
    ],
    buttonVariant: 'outlined',
  },
];

export const PricingPage: React.FC = () => {
  return (
    <>
    {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Choose a plan that's right for you.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {plans.map((plan) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={plan.title}
              xs={12}
              sm={plan.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={plan.title}
                  subheader={plan.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={plan.type === PlanType.Standard ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ₹{plan.price}
                    </Typography>
                  </Box>
                  <ul style={{minHeight: '200px'}}>
                    {plan.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}.
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <PurchaseButton plan={plan.type} variant={plan.buttonVariant} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}