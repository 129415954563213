import { gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { PropsOf } from "@emotion/react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {v4 as uuidV4} from 'uuid';
import { PlanType } from "./PricingPage";

const PURCHASE_QUERY = gql`
  mutation MakePurchase($plan: PlanType!, $userId: String!, $transactionId: String!, $mobileNum: String!) {
    purchasePlan(plan: $plan, userId: $userId, transactionId: $transactionId, mobileNum: $mobileNum) {
      payload
    }
  }
`

interface Props {
  plan: PlanType
  variant: PropsOf<typeof Button>['variant']
}

export const PurchaseButton: React.FC<Props> = ({plan, variant}) => {
  const {user, isLoading} = useAuth0();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [mobileNum, setMobileNum] = useState<string | null>(null);
  const navigate = useNavigate();
  const [initiatePurchase, {loading}] = useMutation(PURCHASE_QUERY);

  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  const handlePurchaseClick = async () => {
    if (!user) {
      console.log('No user found... redirecting... ')
      return navigate(`/login`)
    }

    openDialog();
  }

  const purchase = async () => {
    const response = await initiatePurchase({
      variables: {
        plan,
        userId: user?.email,
        mobileNum,
        transactionId: uuidV4()
      },
    });
    const ppPayload: string = response.data.purchasePlan.payload;
    const ppResponse = JSON.parse(ppPayload);

    const {url} = ppResponse.data.instrumentResponse.redirectInfo;
    window.location.href = url;
  }

  const isValidMobileNum = () => {
    return mobileNum?.length === 10;
  }

  return (
    <>
      <Button
        fullWidth
        variant={variant}
        onClick={handlePurchaseClick}
        disabled={loading || isLoading}
      >
        {loading ? 'Loading...' : 'Purchase'}
      </Button>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To initiate the purchase please provide your mobile number. 
            We will try to get in touch with you on this number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="mobileNum"
            label="Mobile"
            type="tel"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setMobileNum(e.target.value)
            }}
            value={mobileNum || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={purchase} disabled={loading || !isValidMobileNum()}>
            {loading ? 'Loading...' : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
    
  )
}