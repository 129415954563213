import { Link, LinkProps } from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const LinkRouter: React.FC<LinkRouterProps> = (props) => (
  <Link {...props} component={RouterLink} />
)